import { Query } from '@datorama/akita';
import {UiState, UiStore} from './ui.store';

import {Injectable} from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class UiQuery extends Query<UiState> {
    constructor(protected store: UiStore) {
        super(store);
    }

    selectToken$ = this.select(state => state.token);
    selectLineCount$ = this.select(state => state.lineCount);
    selectUser$ = this.select(state => state.user);
    selectGoeError$ = this.select(state => state.goeError);
}
